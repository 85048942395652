module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-fontawesome-css/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"short_name":"Apollo Insurance","name":"Apollo Insurance","background_color":"#fff","display":"fullscreen","icon":"/Users/diegovaladares/git/website/src/images/favicon-32x32.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"abaebf92c42524bfe2e2f33f8d2a0196"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
